import { ClientOnly } from "components/client-only";
import { Searchbar } from "components/forms/searchbar";
import { LogoIcon } from "components/svg/logo-icon";
import { getTotalBasketItems } from "lib/basket/helpers";
import { useBasket } from "lib/hooks/basket/use-basket";
import { useInput } from "lib/hooks/use-input";
import { useUser } from "lib/hooks/use-user";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { HeaderLink } from "./header-link";

interface Props {}
export const Header = ({}: Props) => {
  const [showingMenu, setShowMenu] = useState<boolean>(false);
  const { asPath } = useRouter();
  const currentPath = asPath.split("/")[1];
  const search = useInput<string>("");
  const { userData } = useUser();
  const [basket, _] = useBasket();
  const totalItems = getTotalBasketItems(basket);

  const [showSearch, setShowSearch] = useState<boolean>(true);

  const handleScroll = () => {
    let lastY = 0;
    let currentY = window.scrollY;

    if (lastY < currentY && currentY > 100) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }

    lastY = currentY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className='[ p-4 flex flex-row flex-wrap justify-around ] [ md:p-6 ] [ md:grid md:grid-cols-12 md:grid-rows-2 md:gap-x-4 md:gap-y-1 ] [ xl:grid-rows-4 xl:p-0 xl:py-6 xl:px-4 2xl:px-2 ]  lg:relative z-30 bg-white max-w-screen-2xl mx-auto sticky top-0  '>
        <section
          id='logoSection'
          className='hidden [ xl:block xl:col-start-1 xl:col-span-1 xl:row-span-4 ] z-10 '>
          <Link href='/'>
            <a>
              <LogoIcon className='h-40 m-auto' />
            </a>
          </Link>
        </section>
        <section
          id='menuButtonSection'
          className='[ w-1/6 order-1 mb-auto justify-start ] [ md:w-full md:col-start-12 md:col-span-1 md:row-span-2 md:justify-end md:my-auto ] [ xl:hidden ] flex flex-row  z-10'>
          <button
            className='self-start md:self-center flex flex-col md:-mt-3'
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(!showingMenu);
            }}>
            {showingMenu ? (
              <svg
                aria-label='close menu icon'
                className='block mr-auto h-7 w-7 md:h-12 md:w-12 '
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            ) : (
              <svg
                aria-label='open menu icon'
                className='block h-7 w-7 md:h-12 md:w-12 mr-auto'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
            )}
            <span className='text-2xs md:text-base md:-mt-1' id='menuButton'>
              Menu
            </span>
          </button>
        </section>
        <section
          id='iconSection'
          className='hidden [ xl:flex xl:flex-row xl:col-span-2  xl:col-start-11 xl:row-span-2 xl:justify-center ] h-20 z-10'>
          <img
            alt='Icon of two hands holding'
            src='/assets/images/value-icon-1.png'
            className='h-full -mr-4'
          />
          <img
            alt='Icon of protest signs'
            src='/assets/images/value-icon-2.png'
            className='h-full -mr-2'
          />
          <img
            alt='Icon of a tree'
            src='/assets/images/value-icon-3.png'
            className='h-full '
          />
        </section>
        <section
          id='titleSection'
          className='[ w-4/6 order-2 ] [ md:w-full md:h-full md:col-start-1 md:col-span-7 md:row-start-1 md:row-span-2 ] [ xl:col-start-3 xl:col-end-10 ] flex z-10'>
          <div className='[ grid grid-flow-row-dense mx-auto ] [ xl:inline xl:my-auto xl:ml-0 ]  '>
            <span className='[ text-3xl sm:text-5xl ] [ md:text-6xl ] [  ] font-logo uppercase text-right ml-auto text-red-default'>
              Lighthouse
            </span>
            <span className='[ text-2xs sm:text-xs ] [ md:text-sm ] [ xl:mt-auto xl:text-lg xl:ml-2  ] font-logo-sub capitalize text-right ml-auto'>
              Edinburgh's Radical Bookshop
            </span>
          </div>
        </section>
        <section
          id='cartSection'
          className='[ w-1/6 order-3 justify-end mb-auto ] [ md:w-full md:col-start-9 md:col-span-3 md:row-start-2 ] [ xl:row-start-4 xl:col-start-11 xl:col-span-2 ] flex flex-row z-10'>
          <Link href='/checkout'>
            <a className='self-start  flex flex-col md:flex-row hover:text-red-default'>
              <svg
                aria-label='basket icon'
                className='block ml-auto h-6 w-5  md:order-2 mr-auto md:mr-0 md:ml-1'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
                />
              </svg>
              <span className='text-2xs md:text-base md:my-auto inline md:order-1 mx-auto'>
                <i className='hidden md:inline'>View</i> Basket{" "}
                <ClientOnly>
                  {totalItems > 0 ? (
                    <span className='bg-yellow-light rounded-full inline-block md:w-7 md:h-7 w-4 h-4 text-center'>
                      {totalItems}
                    </span>
                  ) : null}
                </ClientOnly>
              </span>
            </a>
          </Link>
        </section>
        <section
          id='searchSection'
          className={`[ w-full order-4 pt-4 px-12 ] [ md:col-start-9 md:col-span-3 md:row-start-1  md:px-0 md:pt-0 md:mt-auto ] [ xl:row-start-3 xl:col-start-11 xl:col-span-2  ] ${
            showSearch
              ? " transition-all duration-500"
              : "h-0 opacity-0 duration-75"
          } lg:block  ease-in md:h-auto md:opacity-100  `}>
          <Searchbar bind={{ ...search.bind }} reset={search.init} />
        </section>
        <nav
          className={`${
            showingMenu ? "block" : "hidden"
          } [ absolute top-32 sm:top-32 sm:pt-8  w-full h-screen overflow-y-scroll md:pt-44 flex flex-col text-center font-header uppercase bg-white ] [ xl:grid xl:grid-cols-4 xl:grid-rows-2 xl:gap-x-4 xl:relative xl:top-0 xl:pt-0 xl:row-start-3 xl:row-span-2 xl:col-start-3 xl:col-end-10 xl:h-auto xl:overflow-auto  ]  tracking-wide z-50
         `}
          aria-labelledby='menuButton'>
          <HeaderLink
            href='/'
            label='Home'
            size='md'
            selected={currentPath === ""}
          />
          <HeaderLink
            href='/browse'
            label='Browse'
            size='xl'
            selected={currentPath === "browse"}
          />
          <HeaderLink
            href='/posts'
            label='Book List'
            size='md'
            selected={currentPath === "posts"}
          />
          <HeaderLink
            href='/contact-faq'
            label='Contact / FAQ'
            size='md'
            selected={currentPath === "contact-faq"}
          />
          <HeaderLink
            href='/about'
            label='About'
            size='md'
            selected={currentPath === "about"}
          />
          <HeaderLink
            href='/events'
            label='Events / Videos'
            size='xl'
            selected={currentPath === "events"}
          />
          <HeaderLink
            href='/shop'
            label='Merch'
            size='md'
            selected={currentPath === "shop"}
          />

          {!!userData ? (
            <Link href='/account'>
              <a className='mb-4 mx-auto xl:text-base hover:text-red-default [ underline xl:my-auto pt-2 ]'>
                Account
              </a>
            </Link>
          ) : (
            <Link href='/signin'>
              <a className='mb-4 mx-auto xl:text-base hover:text-red-default [ underline xl:my-auto pt-2 ]'>
                Sign in/up
              </a>
            </Link>
          )}
        </nav>
      </header>
    </>
  );
};
